import { defineAsyncComponent } from 'vue'
import { WeightedRandomOption } from './WeightedRandomOption'

enum FlavorRarity {
    Common = 8,
    Uncommon = 4,
    Rare = 2,
    Mythic = 1,
}

type FlavorText = string | Array<string> | {
    component: ReturnType<typeof defineAsyncComponent>
    props?: Record<string, unknown>
}

// ----------------------------------------------------------------------------
// Memes
// ----------------------------------------------------------------------------

const companies = [
    'Akukin Construction',
    'Usada Construction',
    'Oozora Construction',
    'Shiranuhi Construction',
    'Asacoco Pharmaceuticals',
    'Asacoco News Corporation',
    'Roberu & Sons International',
    'Mori Music Records',
    'Kiara Fried Pheonix',
    'Watson Heavy Industries',
    'Watson & Watson',
]

const commonFlavor: Array<FlavorText> = [
    'Every 60 seconds, a minute passes',
    "Everyday we stray further away from Yagoo's dreams",
    "I can't wait for the Hololive Alternative Netflix adaptation",
    "Something is rising and it's not the shield hero",
    "You're not my kaichou",
    'You seeing this shit kaichou?',
    'Kaigai niki strong together',
    'I, for one, welcome our new pekobot overlord',
    'Fubuki is not a cat',
    'If a meme is not in the archives, then it must not exist... probably',
    'Hololive Discord stickers waiting room',
    'The plural of Hololive is Hololive',
    'Stupid sexy overseas bros',
    'Nothing interesting happens',
    'Haachama has a dark, secret past',
    'Big 草',
    'Where is Bloop? Is he safe? Is... he alright?',
    'UCANDOIYT',
    'docchi docchi',
    "Help step-coco, I'm stuck",
    'am website',
    '10q',
    'WAH!',
    'This hits differently',
    'Netflix and Chill? I sleep. Uwu with Cthulu? Real shit!',
    'Nice clock bro',
    'Swimsuits are just underwear you can wear outside',
    'Man I Love Fauna',
    'Man I Love Fuwamoco',
    "You need to let it go, the accident wasn't your fault",
    'Oppai is truth',
    'Medium is premium',
    'Flat is justice',
    '💢💢💢',
    'Ichi Ni San Shi',
]

const uncommonFlavor: Array<FlavorText> = [
    ...companies.map((company) => `Sponsored by ${company}`),
]

const rareFlavor: Array<FlavorText> = [
    "Did you ever hear the tragedy of Darth Noel the wise? I thought not. It's not a story the idols would tell you. It's a Hololive legend. Darth Noel was a dark lord of the seiso, so powerful and so wise she could use superchats to influence Yagoo to create life. She had such a knowledge of the dark side that she could even keep the ones she cared about from getting shadowbanned. The dark side of the seiso is a pathway to many abilities some consider to be unnatural. She became so powerful... the only thing she was afraid of was getting banned, which eventually, of course she did. Unfortunately she taught YouTube-kun everything she knew, then YouTube banned her in her sleep. It's ironic she could save others from the ban hammer, but not herself.",
    [
        'I am the bane of my wallet',
        'Horny is my body, shitpost is my blood',
        'I have created over a thousand memes',
        'Unknown to Yagoo, nor known to Coco',
        'Have withstood bonks from every stream',
        'Yet Fubuki will never marry me',
        'So I swipe, Unlimited Superchatto Taimu',
    ],
    {
        component: defineAsyncComponent(() => import('./RandomSubaru.vue')),
        props: {
            showMessage: true,
        },
    },
]

const mythicFlavor: Array<FlavorText> = [
    {
        component: defineAsyncComponent(() => import('./RandomSubaru.vue')),
        props: {
            isGolden: true,
            showMessage: true,
        },
    },
]

// ----------------------------------------------------------------------------
// Export
// ----------------------------------------------------------------------------

export function createFlavorTextGenerator(): WeightedRandomOption<FlavorText> {
    const flavorText = new WeightedRandomOption<FlavorText>()

    for (const text of commonFlavor) {
        flavorText.addOption(FlavorRarity.Common, text)
    }
    for (const text of uncommonFlavor) {
        flavorText.addOption(FlavorRarity.Uncommon, text)
    }
    for (const text of rareFlavor) {
        flavorText.addOption(FlavorRarity.Rare, text)
    }
    for (const text of mythicFlavor) {
        flavorText.addOption(FlavorRarity.Rare, text)
    }

    return flavorText
}
