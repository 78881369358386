type WeightedOption<T> = {
    weight: number // The higher the weight, the more common it is
    value: T
}

export class WeightedRandomOption<T> {
    private readonly _options: Array<WeightedOption<T>> = []

    addOption(weight: number, value: T): void {
        this._options.push({
            weight,
            value,
        })
    }

    getRandomValue(): T {
        let total = 0
        for (const option of this._options) {
            total += option.weight
        }

        const r = Math.random()
        let n = 0
        for (const option of this._options) {
            n += option.weight

            if (r < (n / total)) {
                return option.value
            }
        }

        // Should never reach this point
        throw new Error('Failed to return an WeightedOption')
    }
}
