import { QRouteTab as _component_q_route_tab } from 'quasar/src/index.dev.js';
import { QTabs as _component_q_tabs } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "container" }

import { APP_TITLE } from '@/common/Constants'
import FlavorText from './FlavorText/FlavorText.vue'

export default /*@__PURE__*/_defineComponent({
  __name: 'HomePage',
  setup(__props) {


return (_ctx: any,_cache: any) => {
  
  
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("h1", null, _toDisplayString(_unref(APP_TITLE)), 1),
        _createVNode(FlavorText)
      ])
    ]),
    _createElementVNode("nav", null, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_q_tabs, { "active-bg-color": "primary" }, {
          default: _withCtx(() => [
            _createVNode(_component_q_route_tab, {
              to: { path: '/' },
              label: "Meme Templates",
              "no-caps": ""
            }),
            _createVNode(_component_q_route_tab, {
              to: { path: '/hologra' },
              label: "HoloGra Screencaps",
              "no-caps": ""
            })
          ]),
          _: 1
        })
      ])
    ]),
    _createVNode(_component_router_view)
  ], 64))
}
}

})