import { QSkeleton as _component_q_skeleton } from 'quasar/src/index.dev.js';
import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  class: "flavor-text",
  title: "Nothing interesting happens"
}
const _hoisted_2 = { key: 0 }

import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'
import { createFlavorTextGenerator } from './createFlavorTextGenerator'


export default /*@__PURE__*/_defineComponent({
  __name: 'FlavorText',
  setup(__props) {

const generator = createFlavorTextGenerator()
const flavorText = shallowRef(generator.getRandomValue())
const nextFlavorText = () => {
    flavorText.value = generator.getRandomValue()
}

const route = useRoute()
watch(() => route.fullPath, nextFlavorText)

return (_ctx: any,_cache: any) => {
  
  const _component_ClientOnly = _resolveComponent("ClientOnly")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ClientOnly, null, {
      placeholder: _withCtx(() => [
        _createVNode(_component_q_skeleton, {
          type: "rect",
          width: "300px",
          height: "1em"
        })
      ]),
      default: _withCtx(() => [
        (typeof flavorText.value === 'string')
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(flavorText.value), 1))
          : (Array.isArray(flavorText.value))
            ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(flavorText.value, (line) => {
                return (_openBlock(), _createElementBlock("span", { key: line }, _toDisplayString(line), 1))
              }), 128))
            : (_openBlock(), _createBlock(_resolveDynamicComponent(flavorText.value.component), _normalizeProps(_mergeProps({ key: 2 }, flavorText.value.props)), null, 16))
      ]),
      _: 1
    })
  ]))
}
}

})